/* eslint-disable */
import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo, useState } from 'react';
import {
  getAuth,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  confirmPasswordReset,
} from 'firebase/auth';
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  getDocs,
  query,
  where,
  arrayUnion,
  serverTimestamp,
  deleteDoc,
} from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { AuthContext } from './auth-context';
import { FIREBASE_API } from 'src/config-global';
import { ref, push, set, get } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import { getMessaging, getToken, onMessage, onTokenRefresh } from 'firebase/messaging';

const firebaseApp = initializeApp(FIREBASE_API);
const messaging = getMessaging(firebaseApp);
const AUTH = getAuth(firebaseApp);
export const DB = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIAL':
      return {
        loading: false /* eslint-disable */,

        user: action.payload.user,
      };
    case 'LOGIN':
    case 'REGISTER':
      return {
        ...state,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        user: null,
      };
    case 'UPDATE_PLAN':
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSubActive, setIsSubActive] = useState(false);
  const [hasAccount, setHasAccount] = useState(false);
  const [stripeCustomerId, setStripeCustomerId] = useState('');
  const [nextBillingDate, setNextBillingDate] = useState(null);
  const [cancellationDate, setCancellationDate] = useState(null);
  const [price, setPrice] = useState(null);
  const [transcriptionNextBillingDate, setTranscriptionNextBillingDate] = useState(null);
  const [transcriptionCancellationDate, setTranscriptionCancellationDate] = useState(null);
  const [transcriptionPrice, setTranscriptionPrice] = useState(null);
  const [isTranscriptionSubActive, setTranscriptionIsSubActive] = useState(false);
  const [isOptimizationUser, setIsOptimizationUser] = useState(false);

  const [optimizationNextBillingDate, setOptimizationNextBillingDate] = useState(null);
  const [optimizationCancellationDate, setOptimizationCancellationDate] = useState(null);
  const [optimizationPrice, setOptimizationPrice] = useState(null);
  const [isOptimizationSubActive, setOptimizationIsSubActive] = useState(false);
  const [currentToken, setCurrentToken] = useState('');
  const [notificationExecute, setNotificationExecute] = useState({
    markerId: null,
    employeeId: null,
  });

  const checkSubscription = async (user, profile) => {
    const { subscriptionId } = profile;

    if (subscriptionId) {
      try {
        const response = await fetch(
          'https://argon-boulevard-399809.oa.r.appspot.com/retrieveCustomerId',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ subscriptionId: subscriptionId }),
          }
        );

        if (!response.ok) throw new Error('Network response was not ok' + response.statusText);
        const result = await response.json();
        const { customerId } = result;
        setStripeCustomerId(customerId);

        if (customerId) {
          const statusResponse = await fetch(
            'https://argon-boulevard-399809.oa.r.appspot.com/check-subscription-status',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ subscriptionId: subscriptionId }),
            }
          );

          if (!statusResponse.ok)
            throw new Error('Network response was not ok' + statusResponse.statusText);

          const statusResult = await statusResponse.json();
          const {
            subscriptionStatus,
            freeTrialStatus,
            nextBillingDate,
            cancellationDate,
            planPrice,
          } = statusResult;

          const formattedNextBillingDate = new Date(nextBillingDate).toISOString().split('T')[0];
          let formattedCancellationDate;
          if (cancellationDate) {
            formattedCancellationDate = new Date(cancellationDate).toISOString().split('T')[0];
          } else {
            formattedCancellationDate = cancellationDate;
          }

          setNextBillingDate(formattedNextBillingDate);
          setCancellationDate(formattedCancellationDate);
          setPrice(planPrice);

          const transkripcija = await checkTranscriptionSubscription(user, profile, customerId);
          const optimizacija = await checkOptimizationSubscription(user, profile, customerId);

          if (subscriptionStatus === 'active' || freeTrialStatus === 'active') {
            console.log('Subscription or Free Trial is active');
            setIsSubActive(true); // Set the subscription as active.
            return { ...user, ...profile, id: user.uid, role: 'admin' };
          } else {
            console.log('Subscription and Free Trial are not active');
            setIsSubActive(false); // Set the subscription as not active.
          }
        }
      } catch (error) {
        console.error('Error:', error);
        setIsSubActive(false); // In case of an error, consider the subscription as not active.
      }
    } else {
      setIsSubActive(false);
    }

    return null;
  };

  const checkTranscriptionSubscription = async (user, profile) => {
    const { transcriptionSubscriptionId } = profile;

    try {
      const statusResponse = await fetch(
        'https://argon-boulevard-399809.oa.r.appspot.com/check-subscription-status',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ subscriptionId: transcriptionSubscriptionId }),
        }
      );

      if (!statusResponse.ok) {
        throw new Error('Network response was not ok' + statusResponse.statusText);
      }
      const statusResult = await statusResponse.json();
      const { subscriptionStatus, freeTrialStatus, nextBillingDate, cancellationDate, planPrice } =
        statusResult;

      const formattedNextBillingDate = new Date(nextBillingDate).toISOString().split('T')[0];
      let formattedCancellationDate;
      if (transcriptionCancellationDate) {
        formattedCancellationDate = new Date(cancellationDate).toISOString().split('T')[0];
      } else {
        formattedCancellationDate = transcriptionCancellationDate;
      }

      setTranscriptionNextBillingDate(formattedNextBillingDate);
      setTranscriptionCancellationDate(formattedCancellationDate);
      setTranscriptionPrice(planPrice);

      if (subscriptionStatus === 'active') {
        console.log('Subscription or Free Trial is active');
        setTranscriptionIsSubActive(true); // Set the subscription as active.
        return { ...user, ...profile, id: user.uid, role: 'admin' };
      } else {
        console.log('Subscription and Free Trial are not active');
        setTranscriptionIsSubActive(false); // Set the subscription as not active.
      }
    } catch (error) {
      console.error('Error:', error);
      setTranscriptionIsSubActive(false); // In case of an error, consider the subscription as not active.
    }

    return null;
  };

  const checkOptimizationSubscription = async (user, profile) => {
    const { optimizationSubscriptionId } = profile;

    try {
      const statusResponse = await fetch(
        'https://argon-boulevard-399809.oa.r.appspot.com/check-subscription-status',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ subscriptionId: optimizationSubscriptionId }),
        }
      );

      if (!statusResponse.ok) {
        throw new Error('Network response was not ok' + statusResponse.statusText);
      }
      const statusResult = await statusResponse.json();
      const { subscriptionStatus, freeTrialStatus, nextBillingDate, cancellationDate, planPrice } =
        statusResult;

      const formattedNextBillingDate = new Date(nextBillingDate).toISOString().split('T')[0];
      let formattedCancellationDate;
      if (optimizationCancellationDate) {
        formattedCancellationDate = new Date(cancellationDate).toISOString().split('T')[0];
      } else {
        formattedCancellationDate = optimizationCancellationDate;
      }

      setOptimizationNextBillingDate(formattedNextBillingDate);
      setOptimizationCancellationDate(formattedCancellationDate);
      setOptimizationPrice(planPrice);

      if (subscriptionStatus === 'active') {
        console.log('Subscription or Free Trial is active');
        setOptimizationIsSubActive(true); // Set the subscription as active.
        return { ...user, ...profile, id: user.uid, role: 'admin' };
      } else {
        console.log('Subscription and Free Trial are not active');
        setOptimizationIsSubActive(false); // Set the subscription as not active.
      }
    } catch (error) {
      console.error('Error:', error);
      setOptimizationIsSubActive(false); // In case of an error, consider the subscription as not active.
    }

    return null;
  };

  const getAdminUid = async (employeeUid) => {
    const realTimedb = getDatabase();

    let mainAdminUid = null;
    let subAdminUid = null;

    // Check if the 'admin' field exists for the employee in Firestore
    // const employeeRef = doc(DB, 'users', employeeUid);
    // const employeeSnapshot = await getDoc(employeeRef);
    // const employeeData = employeeSnapshot.data();

    // if (employeeData && employeeData.admin) {
    //   const adminRef = doc(DB, 'users', employeeData.admin);
    //   const adminSnapshot = await getDoc(adminRef);
    //   const adminData = adminSnapshot.data();

    //   if (adminData && adminData.subAdmin === true) {
    //     subAdminUid = employeeData.admin;
    //   } else {
    //     mainAdminUid = employeeData.admin;
    //   }
    // }

    // Search in Realtime Database for any additional admins
    const adminsRef = ref(realTimedb, 'employees');

    const snapshot = await get(adminsRef);
    for (const adminSnapshot of Object.entries(snapshot.val())) {
      const [adminUid, adminData] = adminSnapshot;
      const userEmployees = adminData.userEmployees;
      if (userEmployees && userEmployees[employeeUid]) {
        // Found a potential admin, check if it's a subAdmin in Firestore
        const potentialAdminRef = doc(DB, 'users', adminUid);
        const potentialAdminSnapshot = await getDoc(potentialAdminRef);
        const potentialAdminData = potentialAdminSnapshot.data();

        if (potentialAdminData && potentialAdminData.subAdmin === true) {
          subAdminUid = adminUid;
        } else if (!mainAdminUid) {
          mainAdminUid = adminUid;
        }
      }
    }

    return { mainAdminUid, subAdminUid };
  };

  const initialize = useCallback(() => {
    console.log('initialize');
    onAuthStateChanged(AUTH, async (user) => {
      console.log('po initialize');
      if (user) {
        const userProfile = doc(DB, 'users', user.uid);
        const docSnap = await getDoc(userProfile);
        const profile = docSnap.data();

        if (profile) {
          setHasAccount(true);
          if (profile.employee === true || profile.subAdmin === true) {
            let { mainAdminUid, subAdminUid } = await getAdminUid(profile.uid);
            const userProfile2 = doc(DB, 'users', mainAdminUid);
            const docSnap2 = await getDoc(userProfile2);
            const profile2 = docSnap2.data();
            const authenticatedUser = await checkSubscription(user, profile2);

            const { optimizationSubscriptions } = docSnap2.data();
            // Check if the current user is listed under the admin's optimization subscriptions
            if (optimizationSubscriptions?.includes(user.uid)) {
              console.log('User is in the admin’s optimization subscription list');
              setIsOptimizationUser(true); // Set state to true if the user is in the admin's subscription
            } else {
              console.log('User is NOT in the admin’s optimization subscription list');
              setIsOptimizationUser(false); // Set state to false if the user is not in the admin's subscription
            }
            setIsAuthenticated(true);
            setIsSubActive(true);
            dispatch({
              type: 'INITIAL',
              payload: {
                user: {
                  ...user,
                  ...profile,
                  adminUid: mainAdminUid,
                  subAdminUid: subAdminUid,
                },
              },
            });
          } else {
            const authenticatedUser = await checkSubscription(user, profile);

            // await checkTranscriptionSubscription(user, profile);
            setIsAuthenticated(!!authenticatedUser);
            dispatch({
              type: 'INITIAL',
              payload: {
                user: {
                  ...authenticatedUser,
                  ...profile,
                },
              },
            });

            // Request Notification Permission and Update FCM Token
            // const messaging = getMessaging();
            // Notification.requestPermission().then(async (permission) => {
            //   if (permission === 'granted') {
            //     console.log("Notification permission granted.");
            //     // Get FCM Token
            //     const currentToken = await getToken(messaging, { vapidKey: "BLru6Gv7MjAq41Z7-IrjBj1vXUEiAI__3ANbJRe6qvA33oTs2PNjTINw4Cbzpnjy8ZMULce34qcjzaVx33TSjPo" });
            //     if (currentToken) {
            //       console.log("FCM Token:", currentToken);
            //       await updateFcmToken(user.uid, currentToken);
            //     } else {
            //       console.log("No FCM token available, unable to get token.");
            //     }
            //   } else {
            //     console.log("Unable to get permission to notify.");
            //   }
            // });
          }

          // Check notification permission and retrieve/update FCM token if granted
          if (Notification.permission === 'granted') {
            const messaging = getMessaging();

            // Ensure the service worker is ready
            const registration = await navigator.serviceWorker.ready;
            
            try {
              const currentToken = await getToken(messaging, {
                vapidKey:
                  'BLru6Gv7MjAq41Z7-IrjBj1vXUEiAI__3ANbJRe6qvA33oTs2PNjTINw4Cbzpnjy8ZMULce34qcjzaVx33TSjPo',
                  serviceWorkerRegistration: registration,
              });
              if (currentToken) {
                console.log('FCM token:', currentToken);

                // Check if the token exists in Firestore
                const tokenDocRef = doc(DB, 'users', user.uid, 'fcmTokens', currentToken);
                const tokenDocSnap = await getDoc(tokenDocRef);

                if (tokenDocSnap.exists()) {
                  const notificationsEnabled = tokenDocSnap.data().notificationsEnabled || false;
                  await updateFcmToken(user.uid, currentToken, notificationsEnabled);
                } else {
                  console.log('Token does not exist in Firestore. Not updating token.');
                }
              } else {
                console.log('No registration token available. Request permission to generate one.');
              }
            } catch (error) {
              console.error('An error occurred while retrieving token. ', error);
            }
          }
        } else {
          dispatch({
            type: 'INITIAL',
            payload: {
              user: null,
            },
          });
          setIsAuthenticated(false);
        }
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
        setIsAuthenticated(false);
      }
    });
  }, []);

  const updateUserState = () => {
    console.log('update user state');
    initialize();
  };

  useEffect(() => {
    initialize();
  }, [initialize]);

  const updateFcmToken = async (userId, newToken, notificationsEnabled = true) => {
    const tokensRef = collection(DB, 'users', userId, 'fcmTokens');
    const newTokenDoc = doc(tokensRef, newToken);
    console.log('HERE:', notificationsEnabled);
    try {
      await setDoc(
        newTokenDoc,
        {
          token: newToken,
          notificationsEnabled: notificationsEnabled,
          loggedOut: false,
          createdAt: serverTimestamp(),
        },
        { merge: true }
      );
      console.log(
        `FCM token updated with notificationsEnabled: ${notificationsEnabled} and loggedOut flags at ${new Date().toISOString()}`
      );
    } catch (error) {
      console.error('Error saving new FCM token:', error);
    }
  };

  // onTokenRefresh(messaging, () => {
  //   getToken(messaging, { vapidKey: "BLru6Gv7MjAq41Z7-IrjBj1vXUEiAI__3ANbJRe6qvA33oTs2PNjTINw4Cbzpnjy8ZMULce34qcjzaVx33TSjPo" }).then(refreshedToken => {
  //     console.log('Token refreshed.');
  //     const userId = AUTH.currentUser.uid;
  //     if (userId) {
  //       updateFcmToken(userId);
  //     }
  //   }).catch((err) => {
  //     console.error('Unable to retrieve refreshed token ', err);
  //   });
  // });

  const login = useCallback(async (email, password) => {
    console.log('login');
    const newUser = await signInWithEmailAndPassword(AUTH, email, password);
    const user = newUser.user;

    const userProfile = doc(DB, 'users', user.uid);
    const docSnap = await getDoc(userProfile);
    const profile = docSnap.data();

    if (profile) {
      console.log('v profile');
      setHasAccount(true);

      //Poglej, če so že dovoljeni notifications in shrani token za napravo
      // if (Notification.permission === "granted") {
      //   console.log("Notification permission already granted.");
      //   const currentToken = await getToken(messaging, { vapidKey: "BLru6Gv7MjAq41Z7-IrjBj1vXUEiAI__3ANbJRe6qvA33oTs2PNjTINw4Cbzpnjy8ZMULce34qcjzaVx33TSjPo" });
      //   if (currentToken) {
      //     console.log("FCM Token:", currentToken);
      //     // Check the token's loggedOut status in Firestore
      //     const tokenDocRef = doc(DB, 'users', user.uid, 'fcmTokens', currentToken);
      //     const tokenDocSnap = await getDoc(tokenDocRef);

      //     if (tokenDocSnap.exists()) {
      //       if (tokenDocSnap.data().loggedOut !== true) {
      //         await updateFcmToken(user.uid, currentToken);
      //       } else {
      //         console.log("Notifications are disabled for this token because it is marked as logged out.");
      //       }
      //     } else {
      //       console.log("Token does not exist in Firestore. Not updating token.");
      //     }
      //   } else {
      //     console.log("No FCM token available, unable to get token.");
      //   }
      // } else {
      //   console.log("Notifications are disabled or permission not granted.");
      // }

      if (profile.employee === true || profile.subAdmin === true) {
        console.log('je employee');
        let { mainAdminUid, subAdminUid } = await getAdminUid(profile.uid);
        const userProfile2 = doc(DB, 'users', mainAdminUid);
        const docSnap2 = await getDoc(userProfile2);
        const profile2 = docSnap2.data();
        const authenticatedUser = await checkSubscription(user, profile2);
        setIsAuthenticated(true);
        setIsSubActive(true);
        dispatch({
          type: 'LOGIN',
          payload: {
            user: {
              ...user,
              ...profile,
              adminUid: mainAdminUid,
              subAdminUid: subAdminUid,
            },
          },
        });
      } else {
        const authenticatedUser = await checkSubscription(user, profile);
        // await checkTranscriptionSubscription(user, profile);
        console.log('authenticatedUser ', authenticatedUser);
        console.log(!!authenticatedUser);
        setIsAuthenticated(!!authenticatedUser);
        dispatch({
          type: 'LOGIN',
          payload: {
            user: authenticatedUser ? authenticatedUser : user,
          },
        });
      }
    } else {
      setHasAccount(false);
      setIsAuthenticated(false);
      dispatch({
        type: 'LOGIN',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  const generateCouponCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 4; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const createCoupon = async (couponName, newUser) => {
    console.log('create coupon');
    try {
      const requests = [
        fetch('https://argon-boulevard-399809.oa.r.appspot.com/create-coupon-individual', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ name: couponName }), // Replace with the actual name you want to send
        }),
        fetch('https://argon-boulevard-399809.oa.r.appspot.com/create-coupon-enterprise', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ name: couponName }), // Replace with the actual name you want to send
        }),
      ];

      const [individualResponse, enterpriseResponse] = await Promise.all(requests);

      if (!individualResponse.ok) {
        throw new Error('Failed to create individual coupon: ' + individualResponse.statusText);
      }
      if (!enterpriseResponse.ok) {
        throw new Error('Failed to create enterprise coupon: ' + enterpriseResponse.statusText);
      }

      const individualCoupon = await individualResponse.json();
      const enterpriseCoupon = await enterpriseResponse.json();

      let isUnique = false;
      let couponCode;

      while (!isUnique) {
        couponCode = generateCouponCode();

        const usersRef = collection(DB, 'users');
        const q = query(usersRef, where('couponName', '==', couponCode));
        const querySnapshot = await getDocs(q);

        isUnique = querySnapshot.empty; // if the query returns no results, then the coupon is unique
      }

      const userProfile = doc(collection(DB, 'users'), newUser.user?.uid);
      await updateDoc(userProfile, {
        individualCouponId: individualCoupon.coupon.id,
        enterpriseCouponId: enterpriseCoupon.coupon.id,
        couponName: couponCode,
      });
    } catch (error) {
      console.error('Error creating coupons:', error);
    }
  };

  const register = useCallback(async (email, password, firstName, lastName) => {
    console.log('register');
    const newUser = await createUserWithEmailAndPassword(AUTH, email, password);
    // await sendEmailVerification(newUser.user);
    const userProfile = doc(collection(DB, 'users'), newUser.user?.uid);
    const uniqueCouponName = `${newUser.user?.uid}`;

    await setDoc(userProfile, {
      uid: newUser.user?.uid,
      email,
      displayName: `${firstName} ${lastName}`,
      creationDate: serverTimestamp(),
    });

    const tasksRef = ref(getDatabase(), `pipeline/${newUser.user?.uid}/tasks`);
    console.log('COLORS');
    const defaultTasks = [
      { color: '#7A0916', name: 'Red', order: 0 },
      { color: '#00A76F', name: 'Green', order: 1 },
      { color: '#00B8D9', name: 'Blue', order: 2 },
    ];

    defaultTasks.forEach(async (task) => {
      const newTaskRef = push(tasksRef);
      await set(newTaskRef, {
        ...task,
      });
    });

    createCoupon(uniqueCouponName, newUser);
    setHasAccount(true);
    setIsAuthenticated(true); // As email is not verified and subscription is not checked yet - vcasih false

    // Fetch and store the FCM token for the newly registered user
    // const newToken = await getToken(messaging, { vapidKey: "BLru6Gv7MjAq41Z7-IrjBj1vXUEiAI__3ANbJRe6qvA33oTs2PNjTINw4Cbzpnjy8ZMULce34qcjzaVx33TSjPo" });
    // await updateFcmToken(newUser.user?.uid, newToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user: {
          ...newUser.user,
          uid: newUser.user?.uid,
          email,
          displayName: `${firstName} ${lastName}`,
        },
      },
    });
  }, []);

  const registerEnterprise = useCallback(async (curUser, email, password, firstName, lastName) => {
    console.log('registerEnterprise ', curUser.uid);
    const newUser = await createUserWithEmailAndPassword(AUTH, email, password);
    const userProfile = doc(collection(DB, 'users'), newUser.user?.uid);
    const uniqueCouponName = `${newUser.user?.uid}`;

    await setDoc(userProfile, {
      uid: newUser.user?.uid,
      email,
      displayName: `${firstName} ${lastName}`,
      employee: true,
      admin: curUser.uid,
    });
    const employeeProfilePath = doc(DB, 'employees', curUser.uid);

    await setDoc(
      employeeProfilePath,
      {
        userEmployees: arrayUnion(newUser.user?.uid),
      },
      { merge: true }
    );

    const employeeRTDBPath = `employees/${curUser.uid}/userEmployees/${newUser.user?.uid}`;
    const employeeRTDBRef = ref(getDatabase(), employeeRTDBPath);

    await set(employeeRTDBRef, true);

    createCoupon(uniqueCouponName, newUser);
  }, []);

  // useEffect(() => {
  //   const fetchToken = async () => {
  //     const token = await getToken(messaging, { vapidKey: "BLru6Gv7MjAq41Z7-IrjBj1vXUEiAI__3ANbJRe6qvA33oTs2PNjTINw4Cbzpnjy8ZMULce34qcjzaVx33TSjPo" });
  //     setCurrentToken(token);  // Store the token in a state or context
  //   };

  //   fetchToken();
  // }, []);

  const logout = useCallback(async () => {
    const currentUser = AUTH.currentUser;

    if (currentUser) {
      try {
        // Check if notification permissions are granted
        if (Notification.permission === 'granted') {

          // Ensure the service worker is ready
          const registration = await navigator.serviceWorker.ready;

          const currentToken = await getToken(messaging, {
            vapidKey:
              'BLru6Gv7MjAq41Z7-IrjBj1vXUEiAI__3ANbJRe6qvA33oTs2PNjTINw4Cbzpnjy8ZMULce34qcjzaVx33TSjPo',
            serviceWorkerRegistration: registration,
          });
          if (currentToken) {
            const tokenDocRef = doc(DB, 'users', currentUser.uid, 'fcmTokens', currentToken);
            const tokenDocSnap = await getDoc(tokenDocRef);

            if (tokenDocSnap.exists()) {
              // Set loggedOut flag to true
              await setDoc(tokenDocRef, { loggedOut: true }, { merge: true });
              console.log('FCM token marked as logged out for the current device.');
            } else {
              console.log('Token does not exist in Firestore. Not setting loggedOut flag.');
            }
          }
        } else {
          console.log('Notification permissions not granted.');
        }
      } catch (error) {
        console.error('Error fetching/deleting the FCM token:', error);
      }

      // Proceed with logging out the user
      await signOut(AUTH);
      setHasAccount(false);
      setIsAuthenticated(false);
      dispatch({
        type: 'LOGOUT',
      });
    }
  }, []);

  const forgetPassword = useCallback(async (email) => {
    try {
      await sendPasswordResetEmail(AUTH, email);
      console.log('Password reset email sent successfully');
      return true;
    } catch (error) {
      console.error('Error sending password reset email:', error);
      if (error.code === 'auth/user-not-found') {
        throw new Error('Email not found');
      } else {
        throw new Error('An error occurred');
      }
    }
  }, []);

  const resetPassword = async (actionCode, newPassword) => {
    try {
      await confirmPasswordReset(AUTH, actionCode, newPassword);
      console.info('Password reset successful');
    } catch (error) {
      console.error('Error resetting password:', error.message);
      throw error; // Propagate the error further
    }
  };

  const triggerNotification = (markerId, employeeId) => {
    console.log('settam trigger', markerId, employeeId);
    setNotificationExecute({ markerId, employeeId });
  };

  const checkAuthenticated = isAuthenticated ? 'authenticated' : 'unauthenticated';
  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'firebase',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      login,
      register,
      registerEnterprise,
      logout,
      forgetPassword,
      resetPassword,
      isSubActive,
      hasAccount,
      stripeCustomerId,
      nextBillingDate,
      cancellationDate,
      price,
      updateUserState,
      triggerNotification,
      notificationExecute,
      transcriptionNextBillingDate,
      transcriptionCancellationDate,
      transcriptionPrice,
      isTranscriptionSubActive,
      isOptimizationSubActive,
      updateFcmToken,
      isOptimizationUser,
    }),
    [
      login,
      logout,
      register,
      registerEnterprise,
      forgetPassword,
      resetPassword,
      state.user,
      status,
      isSubActive,
      hasAccount,
      stripeCustomerId,
      nextBillingDate,
      cancellationDate,
      price,
      updateUserState,
      triggerNotification,
      notificationExecute,
      transcriptionNextBillingDate,
      transcriptionCancellationDate,
      transcriptionPrice,
      isTranscriptionSubActive,
      isOptimizationSubActive,
      updateFcmToken,
      isOptimizationUser,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const rtdb = getDatabase(firebaseApp);
